import React, { useState, useEffect } from "react"
import { Line } from "react-chartjs-2"
import { Chart, registerables } from "chart.js"

Chart.register(...registerables)

// styles
const linkStyle = {
  color: "#8954A8",
  fontWeight: "bold",
  fontSize: 16,
  verticalAlign: "5%",
}

const docLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  marginBottom: 24,
}

const descriptionStyle = {
  color: "#232129",
  fontSize: 14,
  marginTop: 10,
  marginBottom: 0,
  lineHeight: 1.25,
}

const docLink = {
  text: "Documentation",
  url: "https://www.gatsbyjs.com/docs/",
  color: "#8954A8",
}

const badgeStyle = {
  color: "#fff",
  backgroundColor: "#088413",
  border: "1px solid #088413",
  fontSize: 11,
  fontWeight: "bold",
  letterSpacing: 1,
  borderRadius: 4,
  padding: "4px 6px",
  display: "inline-block",
  position: "relative",
  top: -2,
  marginLeft: 10,
  lineHeight: 1,
}

// data
const links = [
  {
    text: "Tutorial",
    url: "https://www.gatsbyjs.com/docs/tutorial/",
    description:
      "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
    color: "#E95800",
  },
  {
    text: "How to Guides",
    url: "https://www.gatsbyjs.com/docs/how-to/",
    description:
      "Practical step-by-step guides to help you achieve a specific goal. Most useful when you're trying to get something done.",
    color: "#1099A8",
  },
  {
    text: "Reference Guides",
    url: "https://www.gatsbyjs.com/docs/reference/",
    description:
      "Nitty-gritty technical descriptions of how Gatsby works. Most useful when you need detailed information about Gatsby's APIs.",
    color: "#BC027F",
  },
  {
    text: "Conceptual Guides",
    url: "https://www.gatsbyjs.com/docs/conceptual/",
    description:
      "Big-picture explanations of higher-level Gatsby concepts. Most useful for building understanding of a particular topic.",
    color: "#0D96F2",
  },
  {
    text: "Plugin Library",
    url: "https://www.gatsbyjs.com/plugins",
    description:
      "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
    color: "#8EB814",
  },
  {
    text: "Build and Host",
    url: "https://www.gatsbyjs.com/cloud",
    badge: true,
    description:
      "Now you’re ready to show the world! Give your Gatsby site superpowers: Build and host on Gatsby Cloud. Get started for free!",
    color: "#663399",
  },
]


// markup
const IndexPage = () => {
  const labels = ["2011","2012","2013","2014","2015", "2016", "2017", "2018", "2019", "2020", "2021"]
  const graphData = {
    labels: labels,
    datasets: [
      {
        label: "東京大学",
        data: [ 7, 3, 5, 10, 9, 13, 8, 8, 7, 6, 3],
        borderColor: "rgb(75, 192, 192)",
      },
      {
        label: "東北大学",
        data: [ 28, 20, 25, 30, 27, 29, 30, 26, 23, 22, 22],
        borderColor: "rgb(60,20,130)",
      },
      {
        label: "北海道大学",
        data: [ 6, 8, 7, 9, 10, 11, 10, 9, 16, 8, 10],
        borderColor: "rgb(21,126,45)",
      },
      {
        label: "東京工業大学",
        data: [ 6, 4, 6, 3, 1, 2, 2, 1, 0, 1, 3],
        borderColor: "rgb(0,83,150)",
      },
      {
        label: "一橋大学",
        data: [ 4, 4, 3, 4, 1, 0, 1, 1, 0, 1, 0],
        borderColor: "rgb(183,25,12)",
      },
    ],
  }
  const graphPrivateData = {
    labels: labels,
    datasets: [
      {
        label: "早稲田大学",
        data: [ 77, 56, 36, 52, 46, 54, 36, 38, 29, 37, 31],
        borderColor: "rgb(75, 192, 192)",
      },
      {
        label: "慶應大学",
        data: [ 39, 29, 30, 30, 21, 35, 23, 20, 18, 22, 14],
        borderColor: "rgb(60,20,130)",
      },
      {
        label: "中央大学",
        data: [ 72, 67, 58, 66, 55, 76, 43, 37, 48, 32, 66],
        // data: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: "rgb(229,35,43)",
      },
    ],
  }
  
  return (
    <main className="mx-auto w-full sm:w-[40rem] px-2">
      <title>高崎高校進学実績</title>
      <h1 className="font-bold text-center text-2xl my-4">
      高崎高校進学実績
      </h1>
      <div className=" max-w-4xl mx-auto">
        <h2 className="text-center text-xl font-bold mt-2 sm:mt-6">国公立大学</h2>
      <Line
        height={300}
        width={300}
        data={graphData}
      />
      <h2 className="text-center text-xl font-bold mt-2 sm:mt-6">私立大学</h2>
      <Line
        height={300}
        width={300}
        data={graphPrivateData}
      />
      <p>横軸は卒業年</p>
      </div>
      {/* <ul>
        <li>
          <a
            
            href={`${docLink.url}?utm_source=starter&utm_medium=start-page&utm_campaign=minimal-starter`}
          >
            {docLink.text}
          </a>
        </li>
        {links.map(link => (
          <li key={link.url} >
            <span>
              <a
                
                href={`${link.url}?utm_source=starter&utm_medium=start-page&utm_campaign=minimal-starter`}
              >
                {link.text}
              </a>
              {link.badge && (
                <span aria-label="New Badge">
                  NEW!
                </span>
              )}
              <p>{link.description}</p>
            </span>
          </li>
        ))}
      </ul>
      <img
        alt="Gatsby G Logo"
        src="data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 2a10 10 0 110 20 10 10 0 010-20zm0 2c-3.73 0-6.86 2.55-7.75 6L14 19.75c3.45-.89 6-4.02 6-7.75h-5.25v1.5h3.45a6.37 6.37 0 01-3.89 4.44L6.06 9.69C7 7.31 9.3 5.63 12 5.63c2.13 0 4 1.04 5.18 2.65l1.23-1.06A7.959 7.959 0 0012 4zm-8 8a8 8 0 008 8c.04 0 .09 0-8-8z' fill='%23639'/%3E%3C/svg%3E"
      /> */}
    </main>
  )
}

export default IndexPage
